// @flow
import 'babel-polyfill/dist/polyfill.js';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-select/dist/react-select.css';

import './polyfills';

import './styles/index.css';
import './styles/utility.css';
import './styles/components.css';

const API = process.env.REACT_APP_API;

if (typeof API !== 'string') {
  throw new Error('API not defined');
}

fetch(`${API}config`)
  .then(response => response.json())
  .then(data => {
    // TODO: figure out how to type window with global
    (window as any).LMS_CONFIG = data;
    (window as any).LMS_CONFIG.API = API;

    // Uncomment to manually force a specific theme
    // (window as any).LMS_CONFIG.THEME = 'SAU';

    import('./app').then(app => {
      const loadingDiv = document.querySelector('.cc-initial-loading');

      if (loadingDiv && loadingDiv.parentNode) {
        loadingDiv.parentNode.removeChild(loadingDiv);
      }
      app.default();
    });
  })
  .catch(error => {
    const loadingHeader: null | HTMLHeadingElement = document.querySelector(
      '.cc-loading'
    );

    if (loadingHeader) {
      loadingHeader.style.display = 'none';
    }

    const errorDiv: null | HTMLDivElement = document.querySelector(
      '.cc-error-fetching-config'
    );

    if (errorDiv) {
      errorDiv.style.display = 'block';
    }

    console.error(error);
  });
